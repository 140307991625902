<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          max-height="300px"
          contain
          :src="require('@/assets/hangmancrop.jpg')"
        ></v-img>
      </v-col>
      <v-col class="text-center">
        <h1 class="display-1 font-weight-bold">Welcome to Hangman!</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
